@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewFilter {
	width: 100%;
	height: auto;
	padding: 0.5rem;
	border-radius: 0.5rem;
	font-size: 1.4rem;
	font-weight: 400;
	@include flex_row(space-between, center);
	margin-bottom: 1.2rem;

	&_inner {
		width: auto;
		display: block;

		&_name {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_id {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
		&:hover {
			background-color: map-get($map: $greys, $key: "chalk");
			color: map-get($map: $colors, $key: "main");
			cursor: pointer;
		}
	}
	&_inner2 {
		width: auto;
		@include flex_row(flex-start, center);

		&_photo {
			@include flex_row(center, flex-start);
			height: 100%;
			margin-right: 1rem;
			// justify-self: flex-start;
		}

		&_info {
			width: auto;
			display: block;

			&_name {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");
			}
			&_id {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "400");
			}
		}

		&:hover {
			background-color: map-get($map: $greys, $key: "chalk");
			color: map-get($map: $colors, $key: "main");
			cursor: pointer;
		}
	}

	&:hover {
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}
	&:hover &_inner_name {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}
	&:hover &_inner_id {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&_isSelected {
		@extend .TimeViewFilter;
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
	}

	&_isSelected &_inner2_info_name {
		color: map-get($map: $colors, $key: "main");
	}
	&_isSelected &_inner2_info_id {
		color: map-get($map: $colors, $key: "main");
	}

	&_icon {
		width: 1.8rem;
		height: 1.8rem;
		fill: map-get($map: $colors, $key: "main");
	}
}

.FilterImage {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	@include flex_row(center, center);
	shape-outside: circle(15em at 10% 40%) padding-box;

	&_fallback {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_image {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 1px solid map-get($map: $blueGreys, $key: "subheadings");
	}
}
