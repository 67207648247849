@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTaskSortController {
	width: 100%;

	&_content {
		width: 100%;

		&_inner {
			width: 100%;

			&_option {
				width: auto;
				margin: 0.5rem 0;

				&:last-child {
					margin-top: 2rem;
				}
			}
		}

		&_actions {
			width: 100%;
			margin-top: 4rem;

			&_resetBtn {
				padding: 0.5rem 1.4rem;
				border-radius: 0.5rem;
				background-color: map-get($map: $redMap, $key: "600");
				color: #ffffff;
				font-size: 1.5rem;
				font-weight: 600;
				border: none;
				outline: none;
				&:hover {
					cursor: pointer;
				}
			}
			&_applyBtn {
				padding: 0.5rem 1.4rem;
				border-radius: 0.5rem;
				background-color: map-get($map: $blueGreyMap, $key: "800");
				color: #ffffff;
				font-size: 1.5rem;
				font-weight: 600;
				margin-right: 0.5rem;
				border: none;
				outline: none;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
