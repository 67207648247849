@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewFilterController {
	width: 100%;
	max-width: 80rem;
	height: 15rem;
	padding: 2rem 2rem;
	// border: 2px solid #ccc;
	border-radius: 5rem;

	&_controls {
		position: relative;
		width: 100%;
		@include flex_col(center, center);

		&_button {
			padding: 0.4rem 1rem;
			border-radius: 0.5rem;
			border: 1px solid #ccc;
			outline: none;
			background-color: #fff;
			@include flex_row(center, center);
			margin-bottom: 0.5rem;

			&:hover {
				cursor: pointer;
			}
			&:disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $blueGreyMap, $key: "900");
			}
			&_text {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "900");
				margin-left: 0.5rem;
			}
		}

		&_loadButton {
			padding: 1rem 1rem;
			border-radius: 0.5rem;
			border: 1px solid #ccc;
			outline: none;
			background-color: map-get($map: $blueGreyMap, $key: "900");
			color: map-get($map: $blueGreyMap, $key: "50");
			@include flex_row(center, center);
			font-size: 1.6rem;
			font-weight: 600;
			margin-bottom: 0.5rem;
			margin-top: 1rem;

			&:hover {
				cursor: pointer;
			}

			&:disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}
		}

		&_selected {
			font-size: 1.5rem;
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}
