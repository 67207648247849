@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewTaskSummaryWrapper {
	width: auto;
	height: auto;
	@include flex_row(center, center);
}

.TimeViewTaskSummary {
	width: 60rem;
	height: 6rem;
	border-radius: 5rem;
	padding: 1rem;
	display: block;
	margin: 0 auto;
	margin-bottom: 2rem;
	background-color: map-get($map: $blueGreyMap, $key: "800");
	@include flex_row(space-evenly, center);
	box-shadow: map-get($map: $shadows, $key: "medium");

	&_tile {
		width: 20%;
		height: auto;
		text-align: center;
		flex-grow: 1;
		flex-shrink: 1;

		&_heading {
			font-family: map-get($map: $fonts, $key: "mainSans");
			color: map-get($map: $blueGreys, $key: "subheadings");
			// font-size: 1.5rem;
			font-size: 1.3rem;

			& > b {
				// color: map-get($map: $colors, $key: "mainBlackBlue");
				color: map-get($map: $blueGreyMap, $key: "200");
				font-size: 1.3rem;
				font-weight: 700;
			}
			&_red {
				color: map-get($map: $colors, $key: "mainRed");
				font-size: 1.3rem;
				// font-size: 1.5rem;
			}
			&_purple {
				color: map-get($map: $colors, $key: "main");
				font-size: 1.3rem;
			}
			&_green {
				color: map-get($map: $colors, $key: "mainGreen");
				font-size: 1.3rem;
			}
			&_yellow {
				color: map-get($map: $colors, $key: "altYellow");
				font-size: 1.3rem;
			}
		}

		&_val {
			font-size: 1.6rem;
			// color: map-get($map: $blueGreys, $key: "headings");
			color: map-get($map: $blueGreyMap, $key: "200");
			font-family: map-get($map: $fonts, $key: "altSans");

			&_red {
				@extend .TimeViewTaskSummary_tile_val;
				color: map-get($map: $colors, $key: "mainRed");
			}

			&_green {
				@extend .TimeViewTaskSummary_tile_val;
				color: map-get($map: $colors, $key: "mainGreen");
			}

			&_purple {
				@extend .TimeViewTaskSummary_tile_val;
				color: map-get($map: $colors, $key: "main");
			}

			&_blue {
				@extend .TimeViewTaskSummary_tile_val;
				color: map-get($map: $colors, $key: "mainBlue");
			}
			&_altYellow {
				@extend .TimeViewTaskSummary_tile_val;
				color: map-get($map: $colors, $key: "altYellow");
			}
		}
	}
}

.TimeViewPRNCount {
	width: 6.5rem;
	height: 6.5rem;
	padding: 1rem;
	text-align: center;
	flex-grow: 1;
	flex-shrink: 1;
	border-radius: 5rem;
	border-radius: 50%;
	background-color: map-get($map: $blueGreyMap, $key: "800");
	@include flex_col(center, center);
	margin-bottom: 1rem;
	margin-top: -0.5rem;

	&_heading {
		font-size: 1.3rem;
		font-family: map-get($map: $fonts, $key: "mainSans");
		color: map-get($map: $pinkMap, $key: "500");
	}
	&_val {
		font-size: 1.6rem;
		// color: map-get($map: $blueGreys, $key: "headings");
		color: map-get($map: $pinkMap, $key: "500");
		font-family: map-get($map: $fonts, $key: "altSans");
	}
}

@media only screen and (max-width: 600px) {
	.TimeViewTaskSummary {
		width: 100%;
		height: 4.5rem;
		padding: 0.2rem 0;
		display: block;
		margin: 0 auto;
		margin-bottom: 2rem;
		@include flex_row(space-evenly, center);
		box-shadow: map-get($map: $shadows, $key: "medium");

		&_tile {
			text-align: center;
			flex-grow: 1;
			flex-shrink: 1;

			&_heading {
				font-family: map-get($map: $fonts, $key: "mainSans");
				color: map-get($map: $blueGreys, $key: "subheadings");
				font-size: 1rem;

				& > b {
					color: map-get($map: $blueGreyMap, $key: "100");
					font-size: 1rem;
					font-weight: 700;
				}
				&_red {
					color: map-get($map: $colors, $key: "mainRed");
					font-size: 1.3rem;
					// font-size: 1.5rem;
				}
				&_purple {
					color: map-get($map: $colors, $key: "main");
					font-size: 1rem;
				}
				&_green {
					color: map-get($map: $colors, $key: "mainGreen");
					font-size: 1rem;
				}
				&_yellow {
					color: map-get($map: $colors, $key: "altYellow");
					font-size: 1rem;
				}
			}

			&_val {
				font-size: 1.3rem;
				font-family: map-get($map: $fonts, $key: "altSans");

				&_red {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "mainRed");
				}

				&_green {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "mainGreen");
				}

				&_purple {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "main");
				}

				&_blue {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "mainBlue");
				}
				&_altYellow {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "altYellow");
				}
			}
		}
	}
	.TimeViewPRNCount {
		width: 4.5rem;
		height: 4.5rem;
		padding: 0.2rem 0;

		&_heading {
			font-size: 1rem;
		}
		&_val {
			font-size: 1.3rem;
		}
	}
}

@media only screen and (max-width: 450px) {
	.TimeViewTaskSummary {
		width: 100%;
		height: 4.5rem;
		padding: 0.2rem 0;
		display: block;
		margin: 0 auto;
		margin-bottom: 2rem;
		@include flex_row(space-evenly, center);
		box-shadow: map-get($map: $shadows, $key: "medium");

		&_tile {
			text-align: center;
			flex-grow: 1;
			flex-shrink: 1;

			&_heading {
				font-family: map-get($map: $fonts, $key: "mainSans");
				color: map-get($map: $blueGreys, $key: "subheadings");
				font-size: 1rem;

				& > b {
					color: map-get($map: $blueGreyMap, $key: "100");
					font-size: 1rem;
					font-weight: 700;
				}
				&_red {
					color: map-get($map: $colors, $key: "mainRed");
					font-size: 1rem;
					// font-size: 1.5rem;
				}
				&_purple {
					color: map-get($map: $colors, $key: "main");
					font-size: 1rem;
				}
				&_green {
					color: map-get($map: $colors, $key: "mainGreen");
					font-size: 1rem;
				}
				&_yellow {
					color: map-get($map: $colors, $key: "altYellow");
					font-size: 1rem;
				}
			}

			&_val {
				font-size: 1.3rem;
				font-family: map-get($map: $fonts, $key: "altSans");

				&_red {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "mainRed");
				}

				&_green {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "mainGreen");
				}

				&_purple {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "main");
				}

				&_blue {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "mainBlue");
				}
				&_altYellow {
					font-size: 1.3rem;
					color: map-get($map: $colors, $key: "altYellow");
				}
			}
		}
	}
}
