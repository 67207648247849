@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoryViewFilterController {
	width: 100%;
	display: block;
	margin: 0 auto;
	padding: 2rem 2rem;

	&_facility {
		margin: 2rem 0;
	}
	&_date {
		margin: 2rem 0;
	}
	&_actions {
		margin-top: 2rem;
	}
}
