@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalUserAssignment {
	width: auto;
	padding: 2rem 0;
	border-top: 2px solid #ccc;
	margin-bottom: 4rem;

	&_msg {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
		margin-bottom: 2rem;
	}
	&_selector {
		width: auto;
	}
}
