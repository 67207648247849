@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalBatchActionsModal {
	position: fixed;
	top: 50%;
	left: 50%;
	max-width: 75%;
	max-height: 65%;
	width: 75vw;
	height: 65vh;
	margin-left: -37.5vw;
	margin-top: -32.5vh;
	background-color: #ffffff;
	border-radius: 1rem;
	// box-shadow: map-get($map: $shadows, $key: "focusMode");
	-webkit-box-shadow: 7px 4px 100px 1000px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 7px 4px 100px 1000px rgba(0, 0, 0, 0.4);
	box-shadow: 7px 4px 100px 1000px rgba(0, 0, 0, 0.4);
	z-index: 200;

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 2rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $colors, $key: "mainRed");
			cursor: pointer;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				background-color: map-get($map: $greys, $key: "chalk");
				border-radius: 0.5rem;
				-webkit-transform: rotateZ(360deg);
				transform: rotateZ(360deg);
			}
		}
	}
	&_main {
		@include fill_container;
		max-height: 95%;
		padding: 2rem 2rem;
		padding-bottom: 4rem;
		overflow-y: scroll;
	}
}
