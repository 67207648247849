@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewFilterFacility {
	width: 100%;
	height: 6rem;
	border-radius: 0.5rem;
	@include flex_row(flex-start, center);

	&:hover {
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}
	&:hover &_main_name {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}
	&:hover &_main_id {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&_iconWrapper {
		width: 6rem;
		height: 100%;
		@include flex_row(center, center);

		&_icon {
			width: 3.5rem;
			min-width: 3.5rem;
			height: 3.5rem;
			min-height: 3.5rem;
			fill: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_main {
		width: auto;
		display: block;

		&_name {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_id {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $purpleMap, $key: "600");
		margin-left: auto;
		margin-right: 1rem;
	}

	&_isSelected {
		@extend .TimeViewFilterFacility;
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
	}
	&_isSelected &_main_name {
		color: map-get($map: $colors, $key: "main");
	}
	&_isSelected &_main_id {
		color: map-get($map: $colors, $key: "main");
	}
	&_isSelected &_main_count {
		color: map-get($map: $colors, $key: "main");
	}
}
