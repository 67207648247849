@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewResidentDetails {
	@include fill_container;
	font-family: map-get($map: $fonts, $key: "altSans");

	&_header {
		width: min-content;
		min-width: max-content;
		height: auto;
		@include flex_row(flex-start, center);
		margin-bottom: 4rem;

		&_title {
			width: min-content;
			min-width: max-content;
			display: block;

			&_name {
				font-size: 2.5rem;
				font-weight: 700;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}
			&_newTask {
				width: auto;
				height: auto;
				padding: 1rem 2rem;
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "100");
				background-color: map-get($map: $purpleMap, $key: "700");
				border-radius: 5rem;
				border: none;
				outline: none;
				cursor: pointer;
				margin-top: 0.5rem;
				&:hover {
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
				}
			}
		}
	}
	&_main {
		width: auto;

		&_loader {
			@include flex_col(flex-start, center);
			margin-bottom: 1rem;

			&_msg {
				font-size: 1.6rem;
				font-weight: 600;
				text-align: center;
				color: map-get($map: $colors, $key: "mainPurple");
			}
		}
		&_heading {
			margin-top: 4rem;
			margin-bottom: -4rem;
			font-size: 1.6rem;
			font-size: 600;
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
}
