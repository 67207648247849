@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewResidentPhoto {
	position: relative;
	// padding: 1rem 1rem;
	width: 100%;
	// width: auto;
	height: 100%;
	border-radius: 50%;
	@include flex_col(flex-start, center);
	&:hover {
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
		cursor: pointer;
	}
}
