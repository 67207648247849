@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTasksController {
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 1rem;

	&_actions {
		width: 100%;
		padding: 1rem 0;
		background-color: map-get($map: $blueGreyMap, $key: "800");
		border-radius: 1rem 1rem 0 0;

		&_bottom {
			width: 100%;
			height: auto;
			padding-right: 2rem;
			@include flex_row(flex-start, center);
		}
	}
	&_list {
		width: 100%;
		height: auto;
		display: block;

		&_count {
			@include flex_row(center, flex-start);
			text-align: center;
			font-size: 1.5rem;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
				font-family: map-get($map: $fonts, $key: "altSans");
				margin-right: 0.5rem;
			}
		}
	}
}

.SearchInput {
	width: 100%;
	height: auto;
	margin-top: 2rem;
	// padding: 0 4rem;
	// padding-top: 2rem;
	margin-bottom: 1rem;
	@include flex_row(center, flex-start);

	&_wrapper {
		position: relative;
		width: 45rem;
		height: 3.5rem;
		&_input {
			width: 100%;
			height: 3.5rem;
			border-radius: 5rem;
			border: 1px solid #ccc;
			font-size: 1.6rem;
			background-color: #ffffff;
			outline: none;
			padding-left: 1rem;
			&:hover {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
		}
		&_clear {
			position: absolute;
			top: 0.3rem;
			right: 0.3rem;
			width: 2.8rem;
			height: 2.8rem;
			border-radius: 50%;
			@include flex_row(center, center);
			border: 1px solid map-get($map: $redMap, $key: "700");
			background-color: map-get($map: $redMap, $key: "200");
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $redMap, $key: "600");
			}
			&:hover &_icon {
				fill: #ffffff;
			}

			&_icon {
				width: 2.3rem;
				height: 2.3rem;
				fill: map-get($map: $redMap, $key: "700");
			}
		}
	}
}

.SortIcon {
	width: auto;
	height: auto;
	padding: 0.3rem 0.5rem;
	border-radius: 5rem;
	margin-left: auto;
	@include flex_row(center, center);
	border: 1px solid map-get($map: $blueGreyMap, $key: "400");

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "200");
		margin-right: 0.2rem;
	}
	&_text {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "200");
	}
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $blueGreyMap, $key: "200");
	}
	&:hover &_icon {
		fill: map-get($map: $blueGreyMap, $key: "800");
		cursor: pointer;
	}
	&:hover &_text {
		color: map-get($map: $blueGreyMap, $key: "800");
		cursor: pointer;
	}
}

.SelectAllIcon {
	width: max-content;
	min-width: max-content;
	@include flex_row(flex-start, center);
	margin: 1rem 0;
	margin-right: 1rem;
	margin-left: 2rem;
	&:hover {
		cursor: pointer;
	}
	&:hover &_text {
		color: #ffffff;
	}

	&_icon {
		width: 3rem;
		height: 3rem;
		min-width: 3rem;
		min-height: 3rem;
		fill: map-get($map: $blueGreyMap, $key: "400");
		&:hover {
			cursor: pointer;
			fill: map-get($map: $blueGreyMap, $key: "200");
		}
	}
	&_text {
		margin-left: 1rem;
		color: map-get($map: $blueGreyMap, $key: "200");
		font-size: 1.7rem;
		font-weight: 600;
	}
}

.TaskCounter {
	width: 100%;
	padding: 0 2.5rem;
	@include flex_row(space-between, center);

	&_selected {
		font-size: 1.5rem;
		font-family: map-get($map: $fonts, $key: "altSans");
		@include flex_row(flex-start, center);

		& span {
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "200");

			& > b {
				color: map-get($map: $blueMap, $key: "400");
			}
		}
	}

	&_msg {
		font-size: 1.5rem;
		font-family: map-get($map: $fonts, $key: "altSans");
		@include flex_row(flex-end, center);

		& span {
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "200");

			& > b {
				color: map-get($map: $blueMap, $key: "400");
			}
		}
	}
}
