@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTaskView {
	width: 100%;
	height: auto;
	padding: 2rem 2rem;

	&_header {
		width: 100%;
		height: auto;
		padding: 0 2rem;
		margin-bottom: 4rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
		}
		&_desc {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_main {
		@include fill_container;
		margin-top: 4rem;

		&_msg {
			text-align: center;
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
			margin-bottom: 2rem;
		}

		&_loader {
			width: 100%;
			@include flex_col(center, center);

			&_msg {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "600");
			}
		}
	}
}
