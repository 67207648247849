@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailyViewSwitcher {
	// width: max-content;
	width: 100%;
	height: 4.5rem;
	// border-radius: 1rem;
	// box-shadow: map-get($map: $shadows, $key: "medium");
	@include flex_col(center, center);

	&_inner {
		@include fill_container;
		padding: 0.4rem 0.4rem;
		border-radius: 0.5rem;
		display: block;
		// @include flex_col(center, center);
	}
}

.DailyViewPanels {
	width: 100%;
	height: auto;
	min-height: 40rem;
	margin-top: 4rem;
}
.DailyViewPanel {
	@include fill_container;
	max-height: auto;
}
.DailyViewSwitcherButtons {
	width: max-content;
	max-width: max-content;
	margin-left: auto;
	height: 4.5rem;
	padding: 0.4rem;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "medium");
	@include flex_row(flex-end, center);
}
.DailyViewSwitcherButton {
	width: 10rem;
	height: 100%;
	padding: 0 0.4rem;
	border-radius: 1rem;
	font-size: 1.6rem;
	font-weight: 600;
	color: map-get($map: $blueGreyMap, $key: "600");
	border: none;
	outline: none;
	margin: 0 0.2rem;
	background-color: transparent;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
	}

	&_isActive {
		@extend .DailyViewSwitcherButton;
		background-color: map-get($map: $colors, $key: "main");
		color: map-get($map: $purpleMap, $key: "100");
		box-shadow: map-get($map: $shadows, $key: "purpleRain");
		&:hover {
			cursor: pointer;
			color: map-get($map: $purpleMap, $key: "100");
		}
	}
}

@media only screen and (max-width: 650px) {
	.DailyViewSwitcher {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
	}

	.DailyViewSwitcherButtons {
		margin: 0 auto;
		@include flex_row(center, center);
	}
	.DailyViewPanel {
		@include fill_container;
		min-width: 100%;
		max-width: 100%;
		max-height: auto;
	}
}
