@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailyTimeView {
	width: 100%;
	margin-bottom: 4rem;

	&_header {
		width: 60rem;
		max-width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 1rem;
		}
		&_desc {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_controller {
		width: 100%;
		height: auto;
		@include flex_row(center, center);
		margin: 2rem 0;
	}
	&_main {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_loader {
			width: 100%;
			height: auto;
			@include flex_row(center, center);
		}

		&_taskCount {
			width: 100%;
			height: auto;
			@include flex_row(center, center);
			text-align: center;
			font-size: 1.5rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			font-style: italic;
			color: map-get($map: $blueGreyMap, $key: "600");

			& b {
				font-weight: 800;
				color: map-get($map: $purpleMap, $key: "600");
				margin: 0 0.5rem;
			}
		}
	}
}
