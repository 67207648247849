@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserDropdownOption {
	width: 100%;
	padding: 1.2rem 1rem;
	font-size: 1.4rem;
	font-weight: 500;
	@include flex_row(flex-start, center);
	color: map-get($map: $blueGreys, $key: "headings");
	// margin-bottom: 1.5rem;
	outline: none;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;

	&_details {
		width: auto;
		height: auto;
		display: block;
		&_label {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_id {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
			font-family: map-get($map: $fonts, $key: "altSans");
		}
	}

	&:hover &_details {
		width: auto;
		height: auto;

		&_label {
			cursor: pointer;
			color: map-get($map: $colors, $key: "main");
		}
		&_id {
			color: map-get($map: $colors, $key: "main");
			cursor: pointer;
		}
	}

	&:hover {
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
		background-color: map-get($map: $greys, $key: "chalk");
	}
	&:focus,
	&:focus-visible,
	&:focus-within {
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
		background-color: map-get($map: $greys, $key: "chalk");
	}

	&_badge {
		width: auto;
		height: 100%;
		margin-right: 2rem;

		&_wrapper {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			box-shadow: map-get($map: $shadows, $key: "medium");
			background-color: map-get($map: $blueGreyMap, $key: "200");
			border: 1px solid #ccc;
			@include flex_row(center, center);

			&_initials {
				font-size: 1.7rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "700");
			}
		}
	}

	&_isSelected {
		@extend .UserDropdownOption;
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
		background-color: map-get($map: $greys, $key: "chalk");
	}
	&_isDisabled {
		@extend .UserDropdownOption;
		opacity: 0.5;
		cursor: not-allowed;
		&:hover {
			cursor: not-allowed;
		}
		& span {
			cursor: not-allowed;
		}
	}
	&_checked {
		margin-left: auto;
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $colors, $key: "main");
	}
}
