@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTaskList {
	width: 100%;
	height: 60rem;
	padding: 2rem 2rem;

	&_inner {
		@include fill_container;
		// border: 2px solid #ccc;
		padding-right: 1rem;
		border-radius: 0.5rem;
		list-style: none;
		overflow-y: scroll;

		-webkit-overflow-scrolling: auto;
		/* Background tracking */
		&::-webkit-scroll-track {
			background-color: map-get($map: $blueGreyMap, $key: "400");
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 1rem;
			border-radius: 5rem;
			background-color: map-get($map: $blueGreyMap, $key: "300");
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "600");
			border-radius: 5rem;
		}
	}
}

.NoMatchesFound {
	width: 100%;
	height: auto;
	margin: 2rem 0;
	@include flex_row(center, center);

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $redMap, $key: "600");
	}
	&_msg {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
}
