@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.CheckboxSM {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: auto;
	position: relative;
	@include flex_row(flex-start, center);
	// margin-top: 1rem;

	&_checkbox {
		width: 1rem;
		visibility: hidden;
		position: relative;
		&:hover {
			-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
			cursor: pointer;
		}
	}

	&_label {
		margin-left: 0.7rem;
		font-size: 1.2rem;
		margin-top: -0.05rem;
		cursor: pointer;
		&:hover {
			cursor: pointer;
		}

		&:hover,
		&:focus-within {
			cursor: pointer;
			-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&::before {
			width: 1.2rem;
			height: 1.2rem;
			position: absolute;
			display: block;
			content: "";
			top: 0;
			left: 0;
			border: 1px solid #777;
			border-radius: 0.3rem;

			&:hover,
			&::focus-within {
				cursor: pointer;
				-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}

		&::after {
			width: 1.2rem;
			height: 1.2rem;
			position: absolute;
			display: none;
			content: "✓";
			font-size: 1.2rem;
			font-weight: bold;
			top: -0.2rem;
			left: 0.1rem;
			border-radius: 0.5rem;
			color: #fff;
		}
	}

	input[type="checkbox"]:checked + &_label::before {
		background-color: map-get($map: $colors, $key: "main");
	}

	input[type="checkbox"]:checked + &_label::after {
		display: block;
	}

	input[type="checkbox"]:disabled + label,
	input[type="checkbox"]:disabled + label::after {
		opacity: 0.3;
	}

	input[type="checkbox"]:focus-within + label,
	input[type="checkbox"]:focus + label::before {
		-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}

	input[type="checkbox"]:hover + label::before {
		-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}
}

.strike {
	opacity: 0.5;
	-webkit-text-decoration: solid line-through
		map-get($map: $colors, $key: "main");
	text-decoration: solid line-through map-get($map: $colors, $key: "main");
}
