@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SearchInput {
	width: 40rem;
	max-width: 100%;
	height: 4rem;
	border-radius: 5rem;
	@include flex_row(center, center);

	&_input {
		@include fill_container;
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
		border-radius: 5rem;
		border: 1px solid #ccc;
		outline: none;
		padding: 0 1rem;
		&::placeholder {
			color: map-get($map: $blueGreyMap, $key: "300");
			font-weight: 500;
		}

		&:hover {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
			cursor: pointer;
		}
		&:focus-within {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 700px) {
	.SearchInput {
		width: 100%;
	}
}
