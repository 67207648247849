@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTaskDetails {
	width: 100%;
	height: auto;

	&_top {
		margin-top: 2rem;
		width: 100%;
		height: auto;
		border-radius: 0.5rem;
		@include flex_row(space-between, center);
		margin-bottom: 4rem;
		border-bottom: 1px solid #ccc;

		&_title {
			width: max-content;
			max-width: 100%;

			&_adl {
				font-size: 1.4rem;
				font-weight: 700;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-left: 2.8rem;
			}
			&_name {
				width: 100%;
				max-width: 100%;
				@include flex_col(flex-start, flex-end);
				position: relative;

				&_icon {
					position: absolute;
					top: 0.2rem;
					left: 0.8rem;

					&_statusBadge {
						width: 1.8rem;
						height: 1.8rem;
						fill: map-get($map: $blueGreys, $key: "subheadings");
						&:hover {
							cursor: pointer;
							fill: map-get($map: $colors, $key: "mainGreen");
						}
						&_isCompleted {
							@extend .HistoricalTaskDetails_top_title_name_icon_statusBadge;
							fill: map-get($map: $colors, $key: "mainGreen");
						}
					}
				}
				&_task {
					font-size: 1.8rem;
					font-weight: 800;
					color: map-get($map: $colors, $key: "mainBlackBlue");
					margin-left: 2.8rem;
					margin-right: auto;
					overflow-wrap: break-word;
					word-wrap: break-word;
					hyphens: auto;
				}
			}
			&_shift {
				width: 100%;
				font-size: 1.4rem;
				font-weight: 700;
				color: map-get($map: $blueMap, $key: "600");
				margin-left: 2.8rem;
				// margin-top: 0.5rem;
			}
			&_originDate {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-left: 2.8rem;
				font-family: map-get($map: $fonts, $key: "altSans");
				margin-top: 0.5rem;
				// margin-top: 0.5rem;
			}
		}

		&_date {
			width: auto;
			@include flex_row(flex-start, flex-end);

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $blueGreys, $key: "subheadings");
				margin-right: 0.5rem;
				// margin-left: auto;
			}
			&_text {
				font-size: 1.5rem;
				font-weight: 600;
				font-family: "Open Sans", "Arial", sans-serif;
				color: map-get($map: $blueGreys, $key: "subheadings");
				align-self: flex-start;
			}
		}

		&_loader {
			width: 100%;
			@include flex_col(center, center);

			&_text {
				font-size: 1.8rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "700");
			}
		}
	}
	&_main {
		@include fill_container;
		padding: 2rem 2rem;

		&_msg {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $orangeMap, $key: "600");
			margin: 3rem 0;
		}
	}
	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
		margin-top: 12rem;
	}
}
