@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdvancedAdlNotes {
	width: 100%;

	&_toggle {
		padding: 0.3rem 1rem;
		background-color: transparent;
		color: map-get($map: $purpleMap, $key: "600");
		font-size: 1.4rem;
		font-weight: 500;
		border: none;
		outline: none;
		&:hover {
			cursor: pointer;
		}
	}

	&_loader {
		width: auto;
		@include flex_row(flex-start, center);
		padding: 0.5rem 2rem;

		&_text {
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_main {
		margin-top: 1rem;
		padding: 1rem;

		&_label {
			width: auto;
			padding: 0 2rem;
			padding-bottom: 1rem;
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}

		&_notes {
			width: auto;
			padding: 0 2rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
	}
}
