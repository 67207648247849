@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTaskEntry {
	width: 100%;
	padding: 1rem 0;
	@include flex_row(flex-start, center);
	margin: 1rem 0;
	border: 1px solid #ccc;
	background-color: map-get($map: $blueGreyMap, $key: "100");
	border-radius: 0.5rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "medium");
	}

	&_selection {
		position: relative;
	}
	&_main {
		width: 100%;
		position: relative;
	}
}

.TaskInfo {
	width: 100%;
	height: 100%;
	@include flex_col(flex-start, flex-start);

	&_resident {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		user-select: none;
	}
	&_task {
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueMap, $key: "700");
		margin: 0.3rem 0;
		user-select: none;
	}
	&_adl {
		font-size: 1.4rem;
		font-weight: 400;
		color: map-get($map: $blueGreyMap, $key: "400");
		user-select: none;
	}
}

.TaskShift {
	width: auto;
	height: auto;
	position: absolute;
	top: -2rem;
	left: 0.2rem;
	user-select: none;

	&_text {
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "400");
		user-select: none;
	}
}

.SelectionIndicator {
	width: 3.5rem;
	min-width: 3.5rem;
	min-height: 3.5rem;
	height: 3.5rem;
	@include flex_row(center, center);
	margin-right: 2.5rem;
	margin-left: 0.5rem;

	&_icon {
		width: 3rem;
		height: 3rem;
		min-width: 3rem;
		min-height: 3rem;
		fill: map-get($map: $blueGreyMap, $key: "700");
		&:hover {
			cursor: pointer;
			fill: map-get($map: $blueGreyMap, $key: "700");
		}
	}
}

.ExceptionIndicator {
	position: absolute;
	top: -0.7rem;
	right: 0.3rem;
	width: auto;
	height: auto;
	// margin-top: -2.5rem;
	// margin-bottom: 1rem;
	@include flex_row(flex-start, center);
}
.ExceptionIcon {
	width: 1.8rem;
	height: 1.8rem;
	fill: map-get($map: $redMap, $key: "600");
}
.ExceptionDesc {
	font-size: 1.3rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
	margin-right: 0.3rem;
}
