@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Sidetab {
	position: fixed;
	left: 0;
	top: 20rem;
	width: 5rem;
	height: 4rem;
	background-color: map-get($map: $colors, $key: "main");
	@include flex_row(center, center);
	border-radius: 0 0.5rem 0.5rem 0;
	box-shadow: map-get($map: $shadows, $key: "purpleRain");
	z-index: 150;

	&:hover {
		cursor: pointer;
		background-color: map-get($map: $colors, $key: "mainBlackBlue");
	}

	&_icon {
		width: 3rem;
		height: 3rem;
		fill: #ffffff;
	}

	&_menu {
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		max-height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(20px);
		@include flex_col(center, center);
		z-index: 9999;

		&_logo {
			width: 100%;
			height: max-content;
			@include flex_col(flex-start, center);

			&_img {
				width: 20rem;
				height: 5rem;
			}

			&_version {
				margin-top: 2rem;
				font-size: 1.5rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "100");
				&:hover {
					cursor: auto;
				}
			}
		}

		&_top {
			width: 100%;
			height: 70vh;

			&_list {
				list-style: none;
				width: 100%;
				height: 100%;
				@include flex_col(center, center);

				&_title {
					font-size: 1.8rem;
				}

				&_item,
				&_version,
				& ul li {
					padding: 1rem 2rem;
					font-size: 2rem;
					font-weight: 700;
					color: #ffffff;
					@include flex_row(flex-end, center);
					cursor: pointer;

					& > a {
						color: #ffffff;
						&:hover {
							cursor: pointer;
							color: map-get($map: $colors, $key: "mainBlue");
						}
					}

					&:first-child {
						border-top: 1px solid rgba(238, 238, 238, 0.3);
					}

					&:nth-child(even) {
						border-top: 1px solid rgba(238, 238, 238, 0.3);
						border-bottom: 1px solid rgba(238, 238, 238, 0.3);
					}

					&_icon {
						width: 1.6rem;
						height: 1.6rem;
						fill: map-get($map: $colors, $key: "main");
					}
				}
				&_version {
					margin-top: 2rem;
					font-size: 1.5rem;
					font-weight: 600;
					color: map-get($map: $blueGreyMap, $key: "100");
					&:hover {
						cursor: auto;
					}
				}
			}
		}
		&_close {
			width: 6rem;
			height: 6rem;
			min-width: 6rem;
			min-height: 6rem;
			@include flex_row(center, center);
			border-radius: 50%;
			background-color: map-get($map: $colors, $key: "mainRed");
			box-shadow: map-get($map: $shadows, $key: "pronounced");
			&:hover {
				cursor: pointer;
			}

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: #ffffff;
			}
		}
	}
}

@media only screen and (max-width: 550px) {
	.Sidetab {
		position: fixed;
		left: 0;
		top: 20rem;
		width: 3.5rem;
		height: 2.5rem;

		&_top {
			width: 100%;
			height: 50vh;
		}

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: #ffffff;
		}
	}
}
