@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateTaskHandler {
	width: 100%;

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_date {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
			@include flex_row(flex-start, center);

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $blueGreys, $key: "subheadings");
				margin-right: 0.5rem;
			}
		}
	}

	&_resident {
		width: max-content;
		height: auto;
		@include flex_row(flex-start, center);

		&_title {
			width: min-content;
			min-width: max-content;
			margin-left: 1rem;

			&_name {
				width: min-content;
				min-width: max-content;
				font-size: 2.5rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "800");
			}
			&_id {
				width: min-content;
				min-width: max-content;
				font-size: 1.6rem;
				font-weight: 600;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}

	&_form {
		width: 100%;
		height: auto;
		border: none;
		outline: none;

		&_residentMsg {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $yellowMap, $key: "600");
			margin-bottom: 2rem;
		}
	}

	&_adl {
		width: auto;
	}
	&_required {
		font-size: 1.4rem;
		color: map-get($map: $redMap, $key: "600");
	}
	&_repeat {
		@include flex_row(flex-start, center);

		&_label {
			font-size: 1.6rem;
			font-weight: 500;
		}
		&_updateBtn {
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");
			font-size: 1.4rem;
			font-weight: 600;
			margin-left: 3rem;
			border: none;
			outline: none;
			cursor: pointer;
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
			}
		}
		&_rangePicker {
			width: 100%;
			margin: 2rem 0;
		}
		&_desc {
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_scheduler {
		width: 100%;
		height: auto;
		font-size: 1.5rem;
		font-weight: 600;

		&_msg {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 4rem;

			& > div {
				font-style: italic;
				font-weight: 400;
			}
		}
		&_option {
			width: auto;
			border: none;
		}
		&_divider {
			width: 20rem;
			@include flex_row(center, center);
			margin: 2rem 0;
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
		&_shifts {
			width: 100%;
			padding: 0 2rem;
			margin-top: 2rem;
			@include flex_row(flex-start, center);
			border: none;
		}
	}

	&_lockTask {
		width: 100%;

		&_desc {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_checkbox {
			margin-top: 2rem;
		}
	}

	&_actions {
		margin-top: 2rem;
		width: 100%;
		min-height: 10rem;
		padding: 0 2rem;
		@include flex_row(flex-end, flex-end);
	}
}
