@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewControllerTabs {
	width: 100%;
	min-height: 15rem;
	height: auto;
	background-color: transparent;
	border-radius: 0.5rem;
	@include flex_col(flex-start, flex-start);
}

.TimeViewTabButtons {
	width: 100%;
	height: auto;
	padding: 0.7rem 1.2rem;
	@include flex_row(flex-start, center);
	border-bottom: 1px solid map-get($map: $blueGreyMap, $key: "100");
}
.TimeViewTabButton {
	width: max-content;
	min-width: max-content;
	max-width: max-content;
	height: auto;
	padding: 1rem 1rem;
	text-align: center;
	border: none;
	outline: none;
	color: map-get($map: $blueGreyMap, $key: "600");
	font-size: 1.6rem;
	font-weight: 500;
	background-color: transparent;
	&:hover {
		cursor: pointer;
		opacity: 1;
		border-bottom: 2px solid map-get($map: $colors, $key: "main");
	}
	&_isActive {
		@extend .TimeViewTabButton;
		background-color: transparent;
		opacity: 1;
		color: map-get($map: $colors, $key: "main");
		font-weight: 500;
		border-bottom: 2px solid map-get($map: $colors, $key: "main");
		transition: all 0.15s ease-in-out;
		&:hover {
			cursor: pointer;
			border-bottom: 2px solid map-get($map: $colors, $key: "mainBlue");
		}
	}
}
.TimeViewTabPanels {
	width: 100%;
	height: auto;
	padding: 3rem 0.5rem;
}
.TimeViewTabPanel {
	width: 100%;
	height: auto;
}
