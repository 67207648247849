@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

$initWidth: 55vw;
$initHeight: 40vh;

.TimeViewSortController {
	position: fixed;
	top: 50%;
	left: 50%;
	max-width: 55%;
	max-height: 40%;
	width: 55vw;
	height: 40vh;
	margin-left: -27.5vw;
	margin-top: -20vh;
	background-color: #ffffff;
	border-radius: 1rem;
	border-top: 10px solid map-get($map: $blueGreyMap, $key: "800");
	// @include add_center_focus($initWidth, $initHeight);
	-webkit-box-shadow: 5px 6px 800px 280px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 5px 6px 800px 280px rgba(0, 0, 0, 0.6);
	box-shadow: 5px 6px 800px 280px rgba(0, 0, 0, 0.6);
	z-index: 9999;

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 2rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $colors, $key: "mainRed");
			cursor: pointer;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				background-color: map-get($map: $greys, $key: "chalk");
				border-radius: 0.5rem;
				-webkit-transform: rotateZ(360deg);
				transform: rotateZ(360deg);
			}
		}
	}

	&_about {
		width: 100%;
		padding: 1rem 2rem;
		padding-bottom: 0;

		&_info {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
			list-style: none;

			&_text {
				@include flex_row(flex-start, baseline);
				margin-bottom: 0.5rem;

				&::before {
					content: "- ";
					// content: "💡";
					display: block;
					color: map-get($map: $blueGreyMap, $key: "400");
					margin-right: 0.3rem;
				}
			}
		}
	}

	&_content {
		width: 100%;
		height: auto;
		padding: 4rem 2rem;

		&_inner {
			width: auto;
			height: auto;

			&_option {
				width: auto;
				min-width: max-content;
				height: auto;
				margin: 0.3rem 0.3rem;
			}
		}

		&_other {
			width: auto;
			padding-top: 1rem;
			padding-left: 0.3rem;
			margin-bottom: 1rem;
		}

		&_actions {
			width: 100%;
			@include flex_row(flex-end, center);
			margin-top: 2rem;

			&_resetBtn {
				padding: 0.5rem 1rem;
				border-radius: 1rem;
				background-color: transparent;
				border: none;
				outline: none;
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $redMap, $key: "600");
				margin-right: 1rem;
				&:hover {
					cursor: pointer;
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
					background-color: map-get($map: $redMap, $key: "600");
					color: #ffffff;
				}
			}
			&_applyBtn {
				padding: 0.5rem 1rem;
				border-radius: 1rem;
				background-color: map-get($map: $purpleMap, $key: "600");
				border: none;
				outline: none;
				font-size: 1.5rem;
				font-weight: 500;
				color: #ffffff;
				&:hover {
					cursor: pointer;
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
				}
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.TimeViewSortController {
		top: auto;
		bottom: 0;
		left: 50%;
		max-width: 100%;
		max-height: 55%;
		width: 100vw;
		height: 55vh;
		margin-left: -50vw;
		margin-top: -27.5vh;
		background-color: #ffffff;
		border-radius: 1rem;

		&_content_actions {
			margin-top: 4rem;
		}
	}
}
