@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewResidentBadge {
	position: absolute;
	width: auto;
	min-width: max-content;
	height: auto;
	padding: 0 0.2rem;
	bottom: -0.7rem;
	left: 50%;
	font-family: map-get($map: $fonts, $key: "altSans");
	border-radius: 5rem;
	background-color: map-get($map: $yellowMap, $key: "500");
	box-shadow: map-get($map: $shadows, $key: "medium");
	z-index: 999;

	&_roomNum {
		font-size: 1.2rem;
		font-weight: 600;
		color: map-get($map: $orangeMap, $key: "800");
	}
}
