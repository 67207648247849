@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewResidentHeader {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	margin-bottom: 4rem;

	&_photo {
		width: auto;
		@include flex_row(flex-start, center);
	}
	&_name {
		width: auto;
		// @include flex_row(flex-start, center);
		@include flex_col(flex-start, center);

		&_text {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
	}
}
