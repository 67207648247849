@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.wasUpdated {
	@include beacon;
}

.TimeViewTaskListEntry {
	position: relative;
	width: 100%;
	height: 12rem;
	// height: 8rem;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
	background-color: map-get($map: $blueGreyMap, $key: "100");
	@include flex_row(flex-start, center);
	margin: 1rem 0;
	transition: all 0.2s ease-in-out;
	scroll-behavior: smooth;

	&:first-of-type {
		margin-top: 0;
	}

	&:hover {
		z-index: 100;
		opacity: 1;
		background-color: #ffffff;
		// box-shadow: map-get($map: $shadows, $key: "focusMode");
		// -webkit-box-shadow: -1px 0px 65px 100px rgba(0, 0, 0, 0.37);
		// -moz-box-shadow: -1px 0px 65px 100px rgba(0, 0, 0, 0.37);
		// box-shadow: -1px 0px 65px 100px rgba(0, 0, 0, 0.37);
		// lighter shadow
		-webkit-box-shadow: -1px 0px 153px 6px rgba(0, 0, 0, 0.37);
		-moz-box-shadow: -1px 0px 153px 6px rgba(0, 0, 0, 0.37);
		box-shadow: -1px 0px 153px 6px rgba(0, 0, 0, 0.37);
	}

	&_photo {
		width: 12rem;
		max-width: 12rem;
		min-width: 12rem;
		height: 100%;
		padding: 1rem 1rem 0 1rem;
		@include flex_col(center, center);
		margin-top: -1rem;
		margin-right: 3rem;
		margin-left: 0.5rem;
	}
	&_details {
		width: 85%;
		height: 100%;
		padding: 0.5rem 0.5rem;
		@include flex_col(space-between, flex-start);
	}
	&_actions {
		width: 2rem;
		// width: auto;
		height: 100%;
		@include flex_col(center, center);
		padding: 0.3rem 0.4rem;
	}

	// STATUS STYLES
	&_isCompleted {
		@extend .TimeViewTaskListEntry;
		opacity: 0.4;
		border-left: 4px solid map-get($map: $colors, $key: "mainGreen");
		z-index: 1;
		&:hover {
			opacity: 0.8;
			border-left: 4px solid map-get($map: $flatColors, $key: "mainGreen");
		}
	}
	&_isMissedEvent {
		@extend .TimeViewTaskListEntry;
		border-left: 4px solid map-get($map: $flatColors, $key: "mainRed");
		transition: all 0.3 ease-in-out;
		z-index: 1;
		&:hover {
			border-left: 4px solid map-get($map: $colors, $key: "mainRed");
		}
	}
	&_isPastDue {
		@extend .TimeViewTaskListEntry;
		border-left: 4px solid map-get($map: $colors, $key: "main");
		z-index: 1;
		&:hover {
			cursor: pointer;
			border-left: 4px solid map-get($map: $colors, $key: "main");
		}
	}
	&_isNotCompleted,
	&_isPending {
		@extend .TimeViewTaskListEntry;
		cursor: pointer;
		border-left: 4px solid map-get($map: $colors, $key: "altYellow");
		z-index: 1;
	}
}

.CreatedTaskIndicator {
	justify-self: flex-end;
	align-self: flex-end;
	padding-top: 0.1rem;
	width: 1.7rem;
	height: 1.7rem;
	cursor: pointer;
	margin-left: 1rem;

	&_icon {
		width: 1.7rem;
		height: 1.7rem;
		fill: map-get($map: $purpleMap, $key: "600");
	}
}

.CreatedTaskLabel {
	width: auto;
	height: auto;
	@include flex_row(flex-start, flex-end);
	// opacity: 0;

	&_label {
		margin-left: 0.3rem;
		font-size: 1.3rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
		@include blink;
	}
}

.ResidentName {
	width: 100%;
	// height: 3rem;
	height: 5rem;
	// @include flex_row(center, flex-end, nowrap);
	display: block;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
	// margin-top: 0.2rem;
	margin-top: 1rem;
	overflow-x: hidden;
	overflow-y: visible;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	padding: 0 0.3rem;

	// /* Background tracking */
	// &::-webkit-scroll-track {
	// 	-webkit-appearance: none;
	// }
	// /* Sets width/height of 'thumb' & hides default */
	// &::-webkit-scrollbar {
	// 	appearance: none;
	// 	-webkit-appearance: none;
	// 	// width: 0.8rem;
	// }
	// &::-webkit-scrollbar-thumb {
	// 	appearance: none;
	// }
}

.TaskInfo {
	width: 100%;
	height: 100%;

	@include flex_row(space-evenly, center);
	// @include flex_col(center, flex-start);
	// align-self: flex-start;
	// margin-top: -2rem;

	&_name {
		width: 60%;
		// height: auto;
		height: 100%;
		padding-top: 1rem;
		// margin-top: 1rem;

		// USE flex_col for stacked text
		// @include flex_col(center, flex-start);

		// NEW STYLES
		@include flex_col(space-between, initial);
		padding-bottom: 0.5rem;

		&_details {
			width: 100%;
			height: auto;

			// USE flex_col for stacked text
			@include flex_col(center, flex-start);

			&_created {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "400");
				font-family: map-get($map: $fonts, $key: "altSans");
			}
		}

		&_quickComplete {
			width: auto;
			min-width: max-content;
			max-width: max-content;
			@include flex_row(flex-start, center);
		}
	}

	&_time {
		width: 40%;
		height: 100%;
		// @include flex_row(center, center);
		@include flex_col(center, center);
		// width: auto;
		// margin-right: auto;
		// @include flex_row(flex-start, center);
	}
}
.TaskADL {
	width: auto;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "400");
	margin: 0.5rem 0;
	margin-right: 0.5rem;
	margin-bottom: 0;
	margin-top: 0;
}
.TaskName {
	// width: auto;
	width: 100%;
	// overflow-x: scroll;
	font-size: 1.4rem;
	font-weight: 700;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin: 0.5rem 0;
}

.TaskADLIcon {
	width: auto;
	margin-right: 1rem;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "800");
	}
}
.TaskTime {
	width: 10ch;
	min-width: 10ch;
	max-width: 10ch;

	// width: auto;
	// min-width: max-content;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
	font-family: map-get($map: $fonts, $key: "altSans");

	&_bubble {
		width: 10ch;
		min-width: 10ch;
		max-width: 10ch;
		border-radius: 5rem;
		text-align: center;

		&_time {
			font-size: 1.4rem;
			font-weight: 500;
		}
		&_isPastDue {
			@extend .TaskTime_bubble;
			width: 12ch;
			min-width: max-content;
			padding: 0 0.4rem;
			border-radius: 5rem;
			text-align: center;
			background-color: map-get($map: $redMap, $key: "200");
			color: map-get($map: $redMap, $key: "700");
			background-color: map-get($map: $purpleMap, $key: "200");
			color: map-get($map: $purpleMap, $key: "700");
		}
	}

	&_isPastDue {
		@extend .TaskTime;
		width: 12ch;
		min-width: max-content;
		padding: 0 0.4rem;
		border-radius: 5rem;
		text-align: center;
		background-color: map-get($map: $redMap, $key: "200");
		color: map-get($map: $redMap, $key: "700");
	}
}
.TaskShift {
	position: absolute;
	top: 0.3rem;
	left: 0.3rem;

	&_label {
		font-size: 1rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}

.CompletedIcon {
	position: absolute;
	top: 0.3rem;
	left: 0.3rem;
	width: auto;
	height: auto;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $greenMap, $key: "500");
	}
}

.AdvancedButton {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	width: auto;
	height: auto;
	padding: 0.2rem 0.4rem;
	border-radius: 0.5rem;
	border: none;
	outline: none;
	background-color: transparent;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $purpleMap, $key: "700");
	z-index: 100;
	&:hover {
		cursor: pointer;
	}
}
.ExceptionIndicator {
	position: absolute;
	top: 0.2rem;
	right: 0.2rem;
	width: auto;
	height: auto;
	// margin-top: -2.5rem;
	// margin-bottom: 1rem;
	@include flex_row(flex-start, center);
}
.ExceptionIcon {
	width: 1.8rem;
	height: 1.8rem;
	fill: map-get($map: $redMap, $key: "600");
}
.ExceptionDesc {
	font-size: 1.3rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
	margin-right: 0.3rem;
}

.QuickCompleteHandler {
	width: auto;
	min-width: max-content;
	max-width: max-content;
	height: auto;
	// height: 5rem;
	// justify-self: flex-end;

	@include flex_row(flex-start, flex-end);
}
.QuickCompleteCheckbox {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	position: relative;
	@include flex_row(flex-start, center);

	&_checkbox {
		width: 1.4rem;
		visibility: hidden;
		position: relative;
		&:hover {
			cursor: pointer;
		}
	}
	&_label {
		margin-left: 0.5rem;
		margin-top: 0.1rem;
		font-size: 1.3rem;
		font-weight: 600;
		color: map-get($map: $greenMap, $key: "500");
		&:hover {
			cursor: pointer;
			color: map-get($map: $colors, $key: "main");
		}
		&:hover &::before {
			border: 1px solid map-get($map: $colors, $key: "main");
			cursor: pointer;
		}

		&::before {
			width: 1.3rem;
			height: 1.3rem;
			position: absolute;
			display: block;
			content: "";
			top: 0.2rem;
			left: 0;
			// border: 1px solid map-get($map: $colors, $key: "main");
			border: 1px solid map-get($map: $greenMap, $key: "500");
			border-radius: 0.3rem;

			&:hover,
			&::before {
				-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
		}

		&::after {
			width: 1.4rem;
			height: 1.4rem;
			position: absolute;
			display: none;
			content: "\2713";
			font-size: 1.4rem;
			font-weight: bold;
			top: 0;
			left: 0.1rem;
			border-radius: 0.5rem;
			color: #fff;
			&:hover {
				cursor: pointer;
			}
		}
	}
	input[type="checkbox"]:checked + &_label::before {
		background-color: map-get($map: $colors, $key: "main");
	}

	input[type="checkbox"]:checked + &_label::after {
		display: block;
	}

	input[type="checkbox"]:disabled + label,
	input[type="checkbox"]:disabled + label::after {
		opacity: 0.3;
	}
	input[type="checkbox"]:disabled + label:hover {
		opacity: 0.3;
		cursor: not-allowed;
	}
}

@media only screen and (max-width: 800px) {
	.TimeViewTaskListEntry {
		height: 15rem;

		&_photo {
			width: 15rem;
			@include flex_col(center, center);
			margin-right: 2rem;
		}
		&_actions {
			width: 2rem;
		}
	}
	.TaskInfo {
		width: 100%;
		height: 100%;

		&_time {
			margin-left: 3rem;
		}
	}
}
@media only screen and (max-width: 550px) {
	.TimeViewTaskListEntry {
		width: 100%;
		// max-height: 14rem;

		&_photo {
			width: 8rem;
			max-width: 8rem;
			min-width: 8rem;
			// margin-top: 0.5rem;
			margin-top: 2rem;
			margin-right: 1rem;
			margin-left: 0.3rem;
		}

		&_details {
			width: 100%;
			height: 8rem;
		}
		&_actions {
			width: 1rem;
		}
	}
	.ResidentName {
		font-size: 1.3rem;
		margin-top: 0.5rem;
	}
	.TaskInfo {
		width: 100%;
		max-width: 100%;

		&_name {
			width: 85%;
			height: auto;
			min-height: 13.5rem;
			max-height: 13.5rem;
			padding-bottom: 0;
		}
		&_time {
			width: auto;
			margin-left: auto;
			// @include flex_row(flex-end, center);
			@include flex_row(center, center);
		}
	}
	.TaskName {
		width: 100%;
		font-size: 1.3rem;
		margin-top: 2rem;
	}
	.TaskADL {
		font-size: 1.3rem;
	}
	.TaskTime {
		font-size: 1.3rem;
	}

	.QuickCompleteHandler {
		margin-bottom: 0.6rem;
		margin-top: auto;
	}

	.MenuIcon {
		width: auto;
		height: auto;
		align-self: flex-end;
		justify-self: flex-end;
		background-color: transparent;
		border: none;
		outline: none;
		margin-bottom: auto;

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreyMap, $key: "500");
			&:hover {
				cursor: pointer;
				fill: map-get($map: $purpleMap, $key: "700");
			}
		}
	}

	.ExceptionDesc {
		font-size: 1.2rem;
		font-weight: 600;
	}
	.ExceptionIcon {
		width: 1.8rem;
		height: 1.8rem;
		fill: map-get($map: $redMap, $key: "600");
	}
}
