@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Loader {
	width: 100%;
	height: 100%;
	@include flex_col(center, center);

	&_loader {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $purpleMap, $key: "700");
		margin-top: 2rem;
	}
}

.SingleTaskActionsHandler {
	@include fill_container;

	&_msg {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}

	&_error {
		color: map-get($map: $redMap, $key: "600");
		font-size: 2rem;
		font-weight: 700;

		&_btn {
			padding: 0.7rem 1.5rem;
			font-size: 1.6rem;
			font-weight: 600;
			color: #ffffff;
			background-color: map-get($map: $redMap, $key: "600");
			margin-top: 3rem;
		}
	}
}
.SingleRemovePastDue,
.SingleChangeStatus,
.SingleRemoveException,
.SingleCompleteHandler {
	width: 100%;
	margin-top: 2rem;

	&_action {
		width: auto;
		margin-bottom: 2rem;
	}

	&_msg {
		font-size: 1.5rem;
		color: map-get($map: $orangeMap, $key: "600");
		margin-bottom: 2rem;
	}
}
