@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HistoricalTaskActions {
	width: 100%;
	height: auto;

	&_inner {
		@include fill_container;
		@include flex_row(flex-end, center);
		padding: 1rem 1rem;
	}
}

// SINGLE
.MarkAsDoneButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $greenMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.ChangeStatusButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $blueMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.5rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.RemoveExceptionButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $redMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.RemovePastDueFromSingleButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $purpleMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}

// BATCH
.MarkAllAsDoneButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $greenMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.SelectAllButton {
	font-size: 1.5rem;
}

.ChangeStatusOfSelectedButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $blueMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.RemoveExceptionsFromSelectedButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $redMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.RemovePastDueFromSelectedButton {
	font-size: 1.5rem;
	padding: 0.5rem 1.2rem;
	color: #ffffff;
	background-color: map-get($map: $purpleMap, $key: "600");
	border-radius: 0.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 0.2rem;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
