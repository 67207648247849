@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewTaskList {
	width: 100%;
	max-width: 90rem;
	display: block;
	margin: 0 auto;
	height: auto;
	min-height: 40rem;
	scroll-behavior: smooth;

	&_taskCount {
		width: 100%;
		height: auto;
		@include flex_row(center, center);
		text-align: center;
		font-size: 1.5rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
		font-style: italic;
		color: map-get($map: $blueGreyMap, $key: "600");

		& b {
			font-weight: 800;
			color: map-get($map: $purpleMap, $key: "600");
			margin: 0 0.5rem;
		}
	}

	&_settings {
		width: 100%;
		height: auto;
		min-height: max-content;
		@include flex_col(center, center);
		margin-bottom: 4rem;

		&_search {
			position: relative;
			margin-top: 1rem;
			width: auto;
			height: auto;
			@include flex_row(center, center);

			&_btn {
				position: absolute;
				right: 1rem;
				width: 2.3rem;
				height: 2.3rem;
				border-radius: 50%;
				@include flex_row(center, center);
				border: none;
				outline: none;
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $redMap, $key: "200");
				}
				&:focus-within {
					cursor: pointer;
					background-color: map-get($map: $redMap, $key: "200");
				}

				&_icon {
					width: 2rem;
					height: 2rem;
					fill: map-get($map: $redMap, $key: "600");
				}
			}
		}
	}
	&_directions {
		width: 100%;
		height: auto;
		padding: 0 4rem;
		margin: 2rem 0 4rem 0;
		@include flex_col(center, center);
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}

	&_listTop {
		width: 100%;
		height: 5rem;
		padding: 0.3rem 1rem;
		border-radius: 1rem 1rem 0 0;
		background-color: map-get($map: $blueGreyMap, $key: "800");
		border: 1px solid #ccc;
		@include flex_row(flex-end, center);

		&_top {
			padding: 0.3rem 0.5rem;
			background-color: map-get($map: $blueGreyMap, $key: "300");
			color: map-get($map: $blueGreyMap, $key: "900");
			font-size: 1.4rem;
			font-weight: 600;
			border: 1px solid #ccc;
			border-radius: 0.5rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
	&_list {
		width: 100%;
		height: auto;
		min-height: 40vh;
		max-height: 80vh;
		padding: 0 2rem;
		border: 1px solid #ccc;
		border-top: 2px solid map-get($map: $blueGreyMap, $key: "300");
		border-radius: 0 0 1rem 1rem;
		overflow-y: auto;
		-webkit-overflow-scrolling: auto;
		/* Background tracking */
		&::-webkit-scroll-track {
			background-color: map-get($map: $blueGreyMap, $key: "400");
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 1rem;
			border-radius: 5rem;
			background-color: map-get($map: $blueGreyMap, $key: "300");
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "600");
			border-radius: 5rem;
		}

		&_item {
			width: auto;
			@include flex_row(flex-start, center);
		}
	}
}

.TaskInstructions {
	width: 100%;
	height: auto;
	padding: 0 4rem;
	margin: 2rem 0 4rem 0;
	@include flex_col(center, center);
	text-align: center;
	font-size: 1.6rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
}

.TaskListOptionsPanel {
	width: 100%;
	height: auto;
	min-height: 5rem;
	padding: 0.5rem 1.5rem;
	border-radius: 1rem 1rem 0 0;
	background-color: map-get($map: $blueGreyMap, $key: "800");
	border: 1px solid #ccc;

	&_topRow {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, flex-start);

		&_facility {
			font-size: 2rem;
			font-weight: 800;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "100");
		}
	}
	&_bottomRow {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, baseline);
	}

	&_left {
		width: 50%;
		height: 100%;
		@include flex_row(flex-start, center);

		&_count {
			width: auto;
			height: auto;
			@include flex_row(center, center);
			text-align: center;
			font-size: 1.3rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			font-style: italic;
			color: map-get($map: $blueGreyMap, $key: "200");

			& b {
				font-weight: 800;
				color: map-get($map: $purpleMap, $key: "300");
				margin: 0 0.5rem;
			}
		}
		&_newTask {
			margin-top: 2rem;
			padding: 1rem 1rem;
			border-radius: 0.5rem;
			background-color: #5751f5;
			color: map-get($map: $blueGreyMap, $key: "50");
			font-size: 1.6rem;
			font-weight: 600;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}
	&_right {
		width: 50%;
		height: 100%;
		@include flex_row(flex-end, center);
		&_top {
			padding: 0.3rem 0.5rem;
			background-color: map-get($map: $blueGreyMap, $key: "300");
			color: map-get($map: $blueGreyMap, $key: "900");
			font-size: 1.4rem;
			font-weight: 600;
			border: 1px solid #ccc;
			border-radius: 0.5rem;
			&:hover {
				cursor: pointer;
			}
		}
		&_sort {
			padding: 0.3rem 0.5rem;
			background-color: map-get($map: $blueGreyMap, $key: "300");
			color: map-get($map: $blueGreyMap, $key: "900");
			font-size: 1.4rem;
			font-weight: 600;
			border: 1px solid #ccc;
			border-radius: 0.5rem;
			@include flex_row(center, center);
			margin: 0 0.5rem;
			&:hover {
				cursor: pointer;
			}

			&_icon {
				width: 1.5rem;
				height: 1.5rem;
				fill: map-get($map: $blueGreyMap, $key: "900");
				margin-right: 0.3rem;
			}
		}
	}
}

.QuickCreateTaskButton {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: none;
	outline: none;
	@include flex_row(center, center);
	background-color: map-get($map: $purpleMap, $key: "600");
	margin-right: 1rem;

	&_icon {
		width: 3rem;
		height: 3rem;
		fill: map-get($map: $purpleMap, $key: "100");
	}
}

.NoTasksFound {
	width: 100%;
	@include flex_row(center, center);
	font-size: 2rem;
	font-weight: 600;
	color: map-get($map: $yellowMap, $key: "600");
}

@media only screen and (max-width: 700px) {
	.TimeViewTaskList {
		width: 100%;
		max-width: 100%;
		min-width: 100%;

		&_settings {
			width: 100%;
			padding: 0 1rem;

			&_search {
				width: 100%;
			}
		}
		&_list {
			padding: 0 0;
		}
	}
}
