@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailyMultiViews {
	@include fill_container;
	padding: 2rem;

	&_viewTitle {
		font-size: 1.8rem;
		font-weight: 600;
		margin-top: 8rem;
		margin-bottom: 2rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
	&_content {
		@include fill_container;
		padding: 2rem 10rem;
	}
}

@media only screen and (max-width: 850px) {
	.DailyMultiViews {
		@include fill_container;
		padding: 2rem 0.5rem;

		&_content {
			@include fill_container;
			padding: 2rem 4rem;
		}
	}
}
@media only screen and (max-width: 650px) {
	.DailyMultiViews {
		@include fill_container;
		padding: 2rem 0.5rem;

		&_content {
			@include fill_container;
			padding: 2rem 0;
		}
	}
}
