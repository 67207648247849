@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewControllerShifts {
	width: 100%;
	height: auto;

	&_inner {
		width: 100%;
		height: auto;
		padding: 2rem 2rem;
		@include flex_col(flex-start, flex-start);

		&_item {
			width: 100%;
			height: auto;
			@include flex_row(center, flex-start);
			min-height: 5rem;
			margin: 0.5rem 0;
		}
	}
	&_selectAll {
		width: 100%;
		height: auto;
		padding: 0.5rem 1rem;
		@include flex_row(flex-start, center);
		margin-bottom: 1rem;

		&_btn {
			padding: 0.5rem 1rem;
			border-radius: 0.5rem;
			background-color: transparent;
			color: map-get($map: $colors, $key: "mainGreen");
			margin-right: 0.5rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "main");
			outline: none;
			border: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}
	&_actions {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3.8rem;
		padding: 0 1rem;
		border-radius: 0 0 1rem 1rem;
		background-color: transparent;
		@include flex_row(flex-end, center);
		background-color: map-get($map: $blueGreyMap, $key: "100");
		z-index: 20;

		&_clearBtn {
			padding: 0.5rem 1rem;
			border-radius: 0.5rem;
			background-color: transparent;
			color: map-get($map: $colors, $key: "mainRed");
			margin-right: 0.5rem;
			outline: none;
			border: 1px solid map-get($map: $colors, $key: "mainRed");
			&:hover {
				cursor: pointer;
			}
		}
		&_saveBtn {
			padding: 0.6rem 1rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $colors, $key: "main");
			color: #ffffff;
			outline: none;
			border: none;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.ShiftSelector {
	width: 100%;
	height: 100%;
	min-height: 5rem;
	padding: 0.5rem 1rem;
	align-self: center;
	@include flex_row(flex-start, center);
	border-radius: 0.5rem;
	border: none;
	outline: none;
	border: 1px solid #ccc;
	background-color: none;
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $greys, $key: "chalk");
	}

	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $purpleMap, $key: "600");
		margin-left: auto;
	}
	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "800");
	}

	&_isSelected {
		@extend .ShiftSelector;
		background-color: map-get($map: $greys, $key: "chalk");
	}
	&_isSelected &_icon {
		fill: map-get($map: $purpleMap, $key: "600");
	}
	&_isSelected &_label {
		color: map-get($map: $purpleMap, $key: "600");
	}
}
