@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentPhotoController {
	width: min-content;
	position: relative;

	&_hover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: hsla(242, 89%, 64%, 0.8);
		z-index: 100;
		font-size: 1.3rem;
		font-weight: 700;
		color: #ffffff;
		@include flex_row(center, center, wrap);
		cursor: pointer;
		transition: all 0.4s ease-in-out;
	}
}
