@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportViewerTableRow {
	@include fill_container;
	height: 10rem;
	padding: 0 1rem;

	&_cells {
		@include fill_container;
		@include flex_row(flex-start, center);
		overflow: hidden;
	}

	&:nth-child(even) {
		background-color: #eaecef;
	}
}
